import { Component, OnInit } from '@angular/core'
import { ForcesService } from 'src/app/services/forces.service'
import { ActivatedRoute } from '@angular/router'
import { map, flatMap, filter } from 'rxjs/operators'
import { isNullOrUndefined } from 'util'
import { Model } from 'src/app/model/force'

@Component({
  selector: 'app-companion-force-overview',
  templateUrl: './companion-force-overview.component.html',
  styleUrls: ['./companion-force-overview.component.scss']
})
export class CompanionForceOverviewComponent implements OnInit {

  models = this.route.params.pipe(
    map(params => params.forceId),
    filter(forceId => !isNullOrUndefined(forceId)),
    flatMap(forceId => this.forcesService.force(forceId))
  )

  selectedModel: Model | undefined

  constructor(
    private route: ActivatedRoute,
    private forcesService: ForcesService,
  ) { }

  ngOnInit() {}

  selectModel(model: Model) {
    this.selectedModel = model
  }

}

import { Component, OnInit } from '@angular/core'
import { ForcesService } from 'src/app/services/forces.service'

@Component({
  selector: 'app-companion-forces',
  templateUrl: './companion-forces.component.html',
  styleUrls: ['./companion-forces.component.scss']
})
export class CompanionForcesComponent implements OnInit {

  forces = this.forcesService.forceDescriptions()

  constructor(
    private forcesService: ForcesService
  ) { }

  ngOnInit() {}

}

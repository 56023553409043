import { Injectable } from '@angular/core'
import { AngularFireAuth } from '@angular/fire/auth'
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore'
import * as firebase from 'firebase/app'
import { defer, from, Observable, of } from 'rxjs'
import { map, mergeMap, shareReplay } from 'rxjs/operators'
import { isNullOrUndefined } from 'util'

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private angularFireAuth: AngularFireAuth,
    private angularFireStore: AngularFirestore,
  ) { }

  private authStateChangedSubject = this.angularFireAuth.authState.pipe(
    mergeMap((authUser, _) => {
      if (!authUser) {
          return of(null)
      }
      return of(authUser)
      // TODO: read user profile from database, check roles
    }),
    shareReplay(1)
  )

  /**
   * Observe the current auth state.
   * This will later return the internal user profile.
   * Currently returns the firebase user.
   */
  onAuthStateChange(): Observable<firebase.User> {
    return this.authStateChangedSubject
  }

  userCollection(): Observable<AngularFirestoreDocument> {
    return this.onAuthStateChange().pipe(
      map(user => {
        if (!isNullOrUndefined(user)) {
          return this.angularFireStore.collection('users').doc(user.uid)
        }
        return null
      })
    )
  }

  signIn(): Observable<firebase.auth.UserCredential> {
    const provider = new firebase.auth.GoogleAuthProvider()
    return defer(() => this.angularFireAuth.auth.signInWithPopup(provider))
  }

  signOut(): Observable<void> {
    return from(this.angularFireAuth.auth.signOut())
  }
}

import { Injectable } from '@angular/core'
import { combineLatest, Observable } from 'rxjs'
import { filter, flatMap, map } from 'rxjs/operators'
import { isNullOrUndefined } from 'util'
import { Force, ForceDescription, Model } from '../model/force'
import { Miniature } from '../model/miniature'
import { AuthService } from './auth.service'
import { MiniaturesService } from './miniatures.service'

@Injectable({
  providedIn: 'root'
})
export class ForcesService {

  constructor(
    private authService: AuthService,
    private miniatureService: MiniaturesService,
  ) { }

  forceDescriptions(): Observable<ForceDescription[]> {
    return this.authService.userCollection().pipe(
      filter(userDoc => !isNullOrUndefined(userDoc)),
      flatMap(userDoc =>
        userDoc
          .collection<ForceDescription>('force_descriptions')
          .valueChanges()
      )
    )
  }

  force(forceId: string): Observable<Model[]> {
    const miniatures = this.miniatureService.miniatures().pipe(
      filter(minis => !isNullOrUndefined(minis))
    )
    const force = this.authService.userCollection().pipe(
      filter(userDoc => !isNullOrUndefined(userDoc)),
      flatMap(userDoc =>
        userDoc
          .collection<Force>('forces')
          .doc<Force>(forceId)
          .valueChanges()
      )
    )

    function findLinkedModel(minis: Miniature[], modelId: string): Miniature | undefined {
      return minis.find(mini =>
        !isNullOrUndefined(mini
          .linkedToModels
          .find(linkedId => linkedId === modelId)
        ))
    }

    return combineLatest(
      miniatures,
      force
    ).pipe(
      map(input => {
        const miniatureInput = input[0]
        const forceInput = input[1]

        return forceInput.models.map(model => {
          const miniature = findLinkedModel(miniatureInput, model.uniqueModelId)
          if (!isNullOrUndefined(miniature)) {
            model.imageUrl = miniature.imageURL
          }
          return model
        })
      })
    )
  }
}

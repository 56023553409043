import { Component, OnInit } from '@angular/core'
import { AuthService } from 'src/app/services/auth.service'
import { isNullOrUndefined } from 'util'
import { Router, ActivatedRoute } from '@angular/router'

@Component({
  selector: 'app-companion-home',
  templateUrl: './companion-home.component.html',
  styleUrls: ['./companion-home.component.scss']
})
export class CompanionHomeComponent implements OnInit {

  authenticated = false

  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.authService.onAuthStateChange().subscribe(
      user => {
        this.authenticated = !isNullOrUndefined(user)
      }
    )
  }

  login() {
    this.authService.signIn().subscribe()
  }

  logout() {
    this.authService.signOut().subscribe()
  }

  start() {
    this.router.navigate(['forces'], {
      relativeTo: this.route
    })
  }

}

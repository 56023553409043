import { Component, OnInit, ViewEncapsulation } from '@angular/core'
import { AuthService } from 'src/app/services/auth.service'
import { isNullOrUndefined } from 'util'
import { Router, ActivatedRoute } from '@angular/router'

@Component({
  selector: 'app-companion',
  templateUrl: './companion.component.html',
  styleUrls: ['./companion.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CompanionComponent implements OnInit {

  constructor(
    private authService: AuthService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.authService.onAuthStateChange().subscribe(user => {
      if (isNullOrUndefined(user)) {
        this.router.navigate(['companion'])
      }
    })
  }

}

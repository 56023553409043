import { Injectable } from '@angular/core'
import { AngularFirestoreCollection } from '@angular/fire/firestore'
import { Observable } from 'rxjs'
import { flatMap, map, shareReplay, filter } from 'rxjs/operators'
import { isNullOrUndefined } from 'util'
import { Miniature } from '../model/miniature'
import { AuthService } from './auth.service'

@Injectable({
  providedIn: 'root'
})
export class MiniaturesService {

  constructor(
    private authService: AuthService,
  ) { }

  private miniaturesSubject: Observable<Miniature[] | null>

  private miniatureCollection(): Observable<AngularFirestoreCollection<Miniature>> {
    return this.authService.userCollection().pipe(
      filter(userDoc => !isNullOrUndefined(userDoc)),
      map(userDoc => userDoc.collection<Miniature>('miniatures'))
    )
  }

  miniatures(): Observable<Miniature[]> {
    if (isNullOrUndefined(this.miniaturesSubject)) {
      this.miniaturesSubject = this.miniatureCollection().pipe(
        flatMap(miniCol => miniCol.valueChanges()),
        shareReplay(1)
      )
    }
    return this.miniaturesSubject
  }
}

import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { StaticComponent } from './components/static/static.component'
import { HomeComponent } from './components/static/home/home.component'
import { PrivacyComponent } from './components/static/privacy/privacy.component'
import { ContactComponent } from './components/static/contact/contact.component'
import { CompanionComponent } from './components/companion/companion.component'
import { CompanionHomeComponent } from './components/companion/pages/companion-home/companion-home.component'
import { CompanionForceOverviewComponent } from './components/companion/pages/companion-force-overview/companion-force-overview.component'
import { CompanionForcesComponent } from './components/companion/pages/companion-forces/companion-forces.component'


const routes: Routes = [
  {
    path: '',
    component: StaticComponent,
    children: [
      {
        path: '',
        component: HomeComponent
      },
      {
        path: 'privacy',
        component: PrivacyComponent
      },
      {
        path: 'contact',
        component: ContactComponent
      }
    ]
  },
  {
    path: 'companion',
    component: CompanionComponent,
    children: [
      {
        path: '',
        component: CompanionHomeComponent
      },
      {
        path: 'forces',
        component: CompanionForcesComponent
      },
      {
        path: 'force/:forceId',
        component: CompanionForceOverviewComponent
      }
    ]
  },
]

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    anchorScrolling: 'enabled',
    useHash: false,
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
